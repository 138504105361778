<template>
  <div>
    <v-btn
      @click="dialog = true"
      class="white--text text-capitalize"
      color="#004643"
    >
      Update Nilai Aset
    </v-btn>

    <v-dialog v-model="dialog" width="50vw">
      <v-card class="px-10 py-10">
        <v-layout row wrap justify-space-between align-center class="mb-4">
          <h1 style="font-size: 1rem; font-weight: 700">
            Nilai Perolehan Aset
          </h1>
          <img
            style="cursor: pointer"
            @click="dialog = false"
            :src="require('../assets/icons/ph_x-bold.svg')"
          />
        </v-layout>

        <v-row class="header-row">
          <v-col
            cols="2"
            class="table-cell text-center font-weight-bold d-flex justify-center align-center"
            >Tanah</v-col
          >
          <v-col
            cols="2"
            class="table-cell text-center font-weight-bold d-flex justify-center align-center"
            >Gedung dan Bangunan</v-col
          >
          <v-col
            cols="2"
            class="table-cell text-center font-weight-bold d-flex justify-center align-center"
            >Jalan, Irigasi, dan Jaringan</v-col
          >
          <v-col
            cols="2"
            class="table-cell text-center font-weight-bold d-flex justify-center align-center"
            >Mesin dan Peralatan</v-col
          >
          <v-col
            cols="2"
            class="table-cell text-center font-weight-bold d-flex justify-center align-center"
            >Aset Tak Berwujud</v-col
          >
          <v-col
            cols="2"
            class="table-cell text-center font-weight-bold d-flex justify-center align-center"
            >Total</v-col
          >
        </v-row>
        <v-row class="data-row">
          <v-col cols="2" class="table-cell text-center">
            {{ formatRupiah(assetPrices[0]?.land_price || 0) }}
          </v-col>
          <v-col cols="2" class="table-cell text-center">
            {{ formatRupiah(assetPrices[0]?.building_price || 0) }}
          </v-col>
          <v-col cols="2" class="table-cell text-center">
            {{ formatRupiah(assetPrices[0]?.infrastructure_price || 0) }}
          </v-col>
          <v-col cols="2" class="table-cell text-center">
            {{ formatRupiah(assetPrices[0]?.machine_price || 0) }}
          </v-col>
          <v-col cols="2" class="table-cell text-center">
            {{ formatRupiah(assetPrices[0]?.intangible_asset_price || 0) }}
          </v-col>
          <v-col cols="2" class="table-cell text-center">
            {{ formatRupiah(assetPrices[0]?.total || 0) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <h1 style="font-size: 1rem; font-weight: 700" class="mt-4 mb-2">
              Update
            </h1>
          </v-col>
        </v-row>

        <v-row style="height: 60px" class="mb-3">
          <v-col cols="4" class="d-flex align-center">Tahun</v-col>
          <v-col cols="4">
            <v-select
              outlined
              dense
              :items="years"
              v-model="year"
              hide-details
              class="pa-0"
              @change="getAssetPrice"
              menu-props="auto"
              content-class="scroll-to-current"
              scrollToSelected
            ></v-select>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid">
          <template v-for="(field, index) in assetFields">
            <v-row style="height: 60px" :key="index">
              <v-col cols="4" class="d-flex align-center"
                >{{ field.label }}
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  class="py-0"
                  required
                  type="number"
                  v-model="field.model"
                  :rules="rules"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-form>

        <v-row>
          <v-col cols="8" class="d-flex justify-end my-3">
            <v-btn
              @click="submit()"
              class="white--text text-capitalize"
              color="#004643"
            >
              Simpan
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pa-0">
            <h1 style="font-size: 1rem; font-weight: 700" class="mb-4">
              History Nilai Perolehan Aset
            </h1>
          </v-col>
        </v-row>

        <main-table-component
          :headers="tableHeaders"
          :items="computedAssetPrices"
          :hideFooter="true"
        />

        <v-overlay :value="isUploading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false" color="red"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import MainTableComponent from "@/components/MainTableComponent.vue";
import { formatRupiah } from "@/plugins/helpers";

export default {
  components: {
    MainTableComponent,
  },
  data() {
    return {
      tableHeaders: [
        {
          text: "Tahun",
          align: "start",
          value: "year",
          sortable: false,
        },
        {
          text: "Tanah",
          align: "start",
          value: "land_price",
          sortable: false,
        },
        {
          text: "Mesin dan Peralatan",
          align: "start",
          value: "machine_price",
          sortable: false,
        },
        {
          text: "Gedung dan Bangunan",
          align: "start",
          value: "building_price",
          sortable: false,
        },
        {
          text: "Jalan Irigasi dan Jaringan",
          align: "start",
          value: "infrastructure_price",
          sortable: false,
        },
        {
          text: "Aset Tak Berwujud",
          align: "start",
          value: "intangible_asset_price",
          sortable: false,
        },
        {
          text: "Diubah Oleh",
          align: "start",
          value: "fullname",
          sortable: false,
        },
        {
          text: "Tanggal Update",
          align: "start",
          value: "updated_at",
          sortable: false,
        },
      ],
      dialog: false,
      isUploading: false,
      snackbar: {
        show: false,
        text: "",
        color: "",
      },
      years: Array.from({ length: 111 }, (_, i) => 1990 + i),
      year: new Date().getFullYear(),
      assetFields: [
        { label: "Tanah", model: null },
        { label: "Mesin dan Peralatan", model: null },
        { label: "Gedung dan Bangunan", model: null },
        { label: "Jalan Irigasi dan Jaringan", model: null },
        { label: "Aset Tak Berwujud", model: null },
      ],
      rules: [
        (value) => !!value || "Isi tidak boleh kosong",
        (value) => !isNaN(parseFloat(value)) || "Harus berupa angka",
      ],
      assetPrices: [],
      valid: false,
    };
  },
  beforeMount() {
    this.init();
  },
  computed: {
    computedAssetPrices() {
      const computedAssetPrices = [];

      this.assetPrices.forEach((assetPrice) => {
        const computedAssetPrice = {
          ...assetPrice,
          land_price: formatRupiah(assetPrice.land_price),
          machine_price: formatRupiah(assetPrice.machine_price),
          building_price: formatRupiah(assetPrice.building_price),
          infrastructure_price: formatRupiah(assetPrice.infrastructure_price),
          intangible_asset_price: formatRupiah(
            assetPrice.intangible_asset_price
          ),
          updated_at: new Date(assetPrice.updated_at).toLocaleDateString(
            "id-ID",
            { day: "numeric", month: "long", year: "numeric" }
          ),
        };

        computedAssetPrices.push(computedAssetPrice);
      });
      return computedAssetPrices;
    },
  },
  methods: {
    showNotification(text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    formatRupiah,
    async init() {
      const response = await this.$axios.get("/asset-prices");

      this.assetPrices = response.data.data.asset_prices;

      if (this.assetPrices[0].year === this.year) {
        this.assetFields[0].model = this.assetPrices[0].land_price;
        this.assetFields[1].model = this.assetPrices[0].machine_price;
        this.assetFields[2].model = this.assetPrices[0].building_price;
        this.assetFields[3].model = this.assetPrices[0].infrastructure_price;
        this.assetFields[4].model = this.assetPrices[0].intangible_asset_price;
      }
    },
    async getAssetPrice() {
      const response = await this.$axios.get(`/asset-prices?year=${this.year}`);
      this.assetFields[0].model =
        response.data?.data?.asset_prices[0]?.land_price;
      this.assetFields[1].model =
        response.data?.data?.asset_prices[0]?.machine_price;
      this.assetFields[2].model =
        response.data?.data?.asset_prices[0]?.building_price;
      this.assetFields[3].model =
        response.data?.data?.asset_prices[0]?.infrastructure_price;
      this.assetFields[4].model =
        response.data?.data?.asset_prices[0]?.intangible_asset_price;
    },
    async submit() {
      this.isUploading = true;
      try {
        this.$refs.form.validate();
        if (this.valid) {
          await this.$axios.post("/asset-prices", {
            year: this.year,
            land_price: this.assetFields[0].model,
            machine_price: this.assetFields[1].model,
            building_price: this.assetFields[2].model,
            infrastructure_price: this.assetFields[3].model,
            intangible_asset_price: this.assetFields[4].model,
          });

          this.assetFields.forEach((field) => (field.model = null));

          this.dialog = false;
          this.showNotification("Data updated sucessfully!", "white");
          location.reload();
        }
      } catch (error) {
        console.error("Upload failed:", error);
        this.showNotification("Upload failed. Please try again.", "error");
      } finally {
        this.isUploading = false;
      }
    },
  },
};
</script>

<style scoped>
.scroll-to-current .v-list-item {
  max-height: 40px;
}

.scroll-to-current .v-list-item--active {
  background-color: #e0e0e0 !important;
}

.table-cell {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

.header-row .table-cell {
  border-top: 1px solid #000000;
}

/* Add bottom border only to the last row */
.data-row .table-cell {
  border-bottom: 1px solid #000000;
}
</style>
