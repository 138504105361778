<template>
  <div>
    <v-alert
        v-if="this.checkedCtp != null && this.checkedCtp.data.is_need_upload && this.alertCheckedCtp"
        v-model="alert"
        color="#F5D0D0"
        closable
    >
      <v-row no-gutters align="top">
        <v-col cols="12" md="8">
          <div class="text-body-2 font-weight-bold" style="font-family: Lexend,serif !important;">⚠️ Terdapat
            data yang kosong pada beberapa gerbang tol.
          </div>
          <div class="text-caption" style="font-family: Lexend,serif !important;">Silakan hubungi pihak MIY atau
            upload data ruas CTP untuk melengkapi data.
          </div>
        </v-col>
        <v-col align="right" cols="12" md="4">
          <v-icon :size="12" @click="alertCheckedCtp = false">mdi-close</v-icon>
          <div class="text-caption font-weight-bold mt-1"
               style="color: #486AF2; cursor: pointer;font-family: Lexend,serif !important;"
               @click="alertDialog = true">Lihat Gerbang
            Tidak Tersedia
          </div>
        </v-col>
      </v-row>
    </v-alert>


    <v-dialog v-model="alertDialog" max-width="610px">
      <v-card class="elevation-10">
        <v-card-title
            class="px-6 py-4 white"
            style="font-size: 16px; font-weight: 400"
        >
          <v-layout row wrap justify-space-between align-center class="px-3 mt-2">
            <div class="font-weight-bold">⚠️ Terdapat data yang kosong pada beberapa gerbang tol.</div>
            <v-btn icon @click.prevent="alertDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
          <div class="text-body-2 mt-7" style="font-family: 'Lexend', sans-serif !important;">Silakan hubungi pihak MIY
            untuk melengkapi data. Berikut data gerbang belum tersedia :
          </div>
          <div class="mb-3">
            <div class="mt-3" v-if="this.checkedCtp.data.atb6.length !== 0">
              <span class="text-body-2 font-weight-bold">ATB6:</span>
              <ul>
                <template v-for="(item) in this.checkedCtp.data.atb6">
                  <li class="text-body-2" :key="item.gate_id" style="font-family: 'Lexend', sans-serif !important;">{{
                      item.name
                    }}: {{ item.error_text }}
                  </li>
                </template>
              </ul>
            </div>

            <div class="mt-3" v-if="this.checkedCtp.data.att5.length !== 0">
              <span class="text-body-2 font-weight-bold">ATT5:</span>
              <ul>
                <template v-for="(item) in this.checkedCtp.data.att5">
                  <li class="text-body-2" :key="item.gate_id" style="font-family: 'Lexend', sans-serif !important;">{{
                      item.name
                    }}: {{ item.error_text }}
                  </li>
                </template>
              </ul>
            </div>

            <div class="mt-3" v-if="this.checkedCtp.data.att6.length !== 0">
              <span class="text-body-2 font-weight-bold">ATT6*:</span>
              <ul>
                <template v-for="(item) in this.checkedCtp.data.att6">
                  <li class="text-body-2" :key="item.gate_id" style="font-family: 'Lexend', sans-serif !important;">{{
                      item.name
                    }}: {{ item.error_text }}
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <span class="text-body-2 mt-3">*Upload data ATT6 CTP untuk melengkapi data</span>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import mockFile from "@/mock/mockFile.js";

export default {
  props: {
    start_date: {
      type: String,
      required: true,
    },
    end_date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkedCtp: null,
      alertCheckedCtp: true,
      alertDialog: false,
    };
  },
  watch: {
    start_date: {
      handler() {
        this.fetchCtpChecked()
      }
    },
    end_date: {
      handler() {
        this.fetchCtpChecked()
      }
    }
  },
  computed: {
    fetchParams() {
      return {
        from: this.start_date,
        to: this.end_date,
        gates: this.formattedGate,
        graph_type: this.selectedPeriod,
      };
    },
  },
  methods: {
    async fetchCtpChecked() {
      try {
        const response = await this.$axios.get("check-miy-data", {
          params: this.fetchParams,
        });
        this.checkedCtp = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  mounted() {
    this.fetchCtpChecked();
  }
};
</script>
