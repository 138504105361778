<template>
  <v-container class="py-5" style="max-width: 90vw">
    <v-row align="center" justify="space-between">
      <v-col cols="auto">
        <title-component :title="title" />
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="white--text text-capitalize"
          color="#004643"
          width="150"
          height="34"
          @click="download"
          >Download</v-btn
        >
      </v-col>
    </v-row>
    <breadcrumbs-component :crumbs="crumbs" />

    <date-filter-component
      :date-from="date1"
      :date-to="date2"
      :func="getData"
    />

    <main-table-component :headers="tableHeaders" :items="computedAssets" />
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DateFilterComponent from "@/components/DateFilterComponent.vue";
import MainTableComponent from "@/components/MainTableComponent.vue";
import * as constant from "@/constants/constant.js";

export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    DateFilterComponent,
    MainTableComponent,
  },
  beforeMount() {
    this.init();
  },
  computed: {
    computedAssets() {
      return this.tableItems.map((item) => {
        return {
          ...item,
          href: `/aset/${constant.categoryToUrl[item.category_id]}/${item.id}`,
        };
      });
    },
  },
  methods: {
    async init() {
      await this.getData();
    },
    async getData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.date1 = dateFrom;
        this.date2 = dateTo;
      }
      const params = {
        from: this.date1,
        to: this.date2,
        status: "Dilelang",
      };
      const response = await this.$axios.get("/all-assets", {
        params,
      });
      this.tableItems = response.data.data.assets;
    },
    async download() {
      const queryParams = {
        from: this.date1,
        to: this.date2,
        status: "Dilelang",
      };

      try {
        const response = await this.$axios.get("/download-assets-by-status", {
          params: queryParams,
        });
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        const formattedDate1 = `${this.date1.slice(9, 11)}
        ${this.date1.slice(5, 7)}${this.date1.slice(0, 4)}`;
        const formattedDate2 = `${this.date2.slice(9, 11)}
        ${this.date2.slice(5, 7)}${this.date2.slice(0, 4)}`;
        a.href = url;
        a.download = `Aset Dilelang${formattedDate1}_${formattedDate2}.xlsx`;
        await a.click();
        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    getInitialDate() {
      const currentDate = new Date();

      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      firstDate.setDate(firstDate.getDate() + 1);

      const lastDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      lastDate.setDate(lastDate.getDate() + 1);

      const formattedFirstDate = firstDate.toISOString()?.slice(0, 10);
      const formattedLastDate = lastDate.toISOString()?.slice(0, 10);

      return {
        date_start: formattedFirstDate,
        date_end: formattedLastDate,
      };
    },
  },
  data() {
    const initialDate = this.getInitialDate();
    return {
      snackbar: false,
      message: "",
      timeout: 3000,
      date1: initialDate.date_start,
      date2: initialDate.date_end,
      title: "List Aset Dilelang",
      tableHeaders: [
        {
          text: "Nama Aset",
          align: "start",
          value: "asset_name",
          sortable: false,
        },
        { text: "Kode Barang", value: "asset_code", sortable: false },
        { text: "Kode Lokasi", value: "asset_location", sortable: false },
        { text: "Koordinat", value: "coordinate", sortable: false },
        { text: "Pemilik", value: "asset_owner", sortable: false },
        { text: "Pengembang", value: "asset_developer", sortable: false },
        { text: "Operator", value: "asset_operator", sortable: false },
        {
          text: "Keterangan Tambahan",
          value: "information",
          sortable: false,
        },
        { text: "Waktu Dilelang", value: "execution_date", sortable: false },
        { text: "Keterangan", value: "status_information", sortable: false },
        { text: "Foto", value: "image_urls", sortable: false },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      tableItems: [],
      crumbs: [
        {
          text: "Layanan Aset",
          disabled: false,
          href: "/aset",
        },
        {
          text: "List Aset Dilelang",
          disabled: true,
          href: "/aset/aset-dilelang",
        },
      ],
    };
  },
};
</script>

<style></style>
