<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
import DateFilterComponent from "@/components/LayananTransaksi/DateFilterComponent.vue";
import ReportSummaryComponent from "@/components/LayananTransaksi/HPT/ReportSummaryComponent.vue";
import RevenueGraphComponent from "@/components/LayananTransaksi/HPT/RevenueGraphComponent.vue";
import TableStyleComponentDetail from "@/components/LayananTransaksi/HPT/TableStyleComponentDetail.vue";
import WarningUploadCTP from "@/components/LayananTransaksi/Alert/WarningUploadCTP.vue";

export default {
  components: {
    WarningUploadCTP,
    LoadingComponent,
    DateFilterComponent,
    ReportSummaryComponent,
    RevenueGraphComponent,
    TableStyleComponentDetail,
  },
  data() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
    return {
      loading: true,
      start_date: firstDayOfMonth.toISOString().split("T")[0],
      end_date: today.toISOString().split("T")[0],
      formatted_date_range: "",
      selectedPeriod: "daily",
      selectedPayment: ["BCA", "MDR", "BRI", "BNI", "CASH"],
      reportData: null,
      fetchDebounceTimeout: null,
      sections: ["BCA", "BNI", "BRI", "Mandiri", "Tunai"],
      detailData: [],
    };
  },
  computed: {
    formattedPayment() {
      return this.selectedPayment.join(",");
    },
    fetchParams() {
      return {
        from: this.start_date,
        to: this.end_date,
        payment_methods: this.formattedPayment,
        graph_type: this.selectedPeriod,
      };
    },
  },
  watch: {
    fetchParams: {
      handler() {
        if (
            this.start_date &&
            this.end_date &&
            this.selectedPeriod &&
            this.selectedPayment.length > 0
        ) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  methods: {
    updateStartDate(newDate) {
      if (this.start_date !== newDate) {
        this.start_date = newDate;
      }
    },
    updateEndDate(newDate) {
      if (this.end_date !== newDate) {
        this.end_date = newDate;
      }
    },
    updateFormattedDateRange(newRange) {
      this.formatted_date_range = newRange;
    },
    updatePeriod(newPeriod) {
      if (this.selectedPeriod !== newPeriod) {
        this.selectedPeriod = newPeriod;
      }
    },
    updatePayment(newPayment) {
      if (JSON.stringify(this.selectedPayment) !== JSON.stringify(newPayment)) {
        this.selectedPayment = newPayment;
      }
    },
    async fetchData() {
      this.loading = true
      try {
        const response = await this.$axios.get("hpt", {
          params: this.fetchParams
        });
        this.reportData = response.data;
        this.loading = false
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchData();
    this.loading = false
  },
};
</script>

<template>
  <div class="pa-0 ma-0">
    <v-row
        v-if="loading"
        justify="center"
        align="center"
        style="height: 100vh"
    >
      <LoadingComponent/>
    </v-row>

    <!-- Content -->
    <div :class="loading ? 'none': ''">
      <div class="pb-3 pt-4 px-10">
        <v-row no-gutters>
          <v-col align="left" class="my-auto pe-5"
                 cols="12"
                 xs="12"
                 sm="12"
                 md="7">

            <WarningUploadCTP :start_date="this.start_date" :end_date="this.end_date"/>
          </v-col>
          <v-col align="right" class="my-auto"
                 cols="12"
                 xs="12"
                 sm="12"
                 md="5">
            <v-sheet>
              <v-row no-gutters>
                <v-col align="left" class="my-auto" cols="2">
                  <v-sheet>
                    <span
                        class="text--black text-uppercase"
                        style="font-size: 12px; font-weight: 400; color: #bbbbbb"
                    >Filter</span
                    >
                    <h6
                        class="text--black"
                        style="font-size: 14px; font-weight: 400"
                    >
                      Pilih Tanggal
                    </h6>
                  </v-sheet>
                </v-col>
                <v-col align="right" class="my-auto" cols="10">
                  <v-sheet>
                    <DateFilterComponent
                        @update:startDate="updateStartDate"
                        @update:endDate="updateEndDate"
                        @update:formattedDateRange="updateFormattedDateRange"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <!-- report summary -->
      <ReportSummaryComponent v-if="reportData && reportData.summary" :data="reportData.summary"/>
      <!-- revenue -->
      <RevenueGraphComponent
          :data="reportData && reportData.graph"
          @update:selectedPeriod="updatePeriod"
          @update:selectedPayment="updatePayment"
      />
      <div class="pb-3 pt-4 px-10">
        <!-- Detail Section -->
        <div class="mb-5 rounded-xl py-4 px-6 text-center bordered">
          <h3 style="font-size: 18px; font-weight: 500" class="text-uppercase">
            Detail
          </h3>
        </div>
        <div class="py-5">
          <v-row class="mb-4">
            <v-col cols="9" class="my-auto">
              <h3 class="text--black" style="font-size: 20px; font-weight: 500">
                {{ formatted_date_range }}
              </h3>
            </v-col>
          </v-row>
          <div v-if="reportData && reportData.details">
            <template v-for="(item, index) in reportData.details">
              <div :key="item.payment_method">
                <TableStyleComponentDetail
                    :data="item"
                    :isEven="index % 2 === 0"
                />
              </div>
            </template>
          </div>
          ⚠️ Krida Technologies tidak bertanggung jawab atas keabsahan data transaksi, silakan cek kembali ke pihak terkait.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bordered {
  background-color: #dae7ff;
  border-left: 4px solid #497bdd;
  border-right: 4px solid #497bdd;
}
</style>
