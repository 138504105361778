<script>
import Chart from "chart.js/auto";
import { monthToYearMonth } from "@/plugins/helpers";

export default {
  data() {
    return {
      remainingAges: [],
      chart: null,
    };
  },
  props: ["assetId"],
  async mounted() {
    await this.fetchData();
    this.initChart();
  },
  methods: {
    async fetchData() {
      try {
        const response = await this.$axios.get(
          `/asset-age-remaining/${this.assetId}`
        );
        this.remainingAges = response.data.data;
        this.initChart();
      } catch (error) {
        console.error(error);
      }
    },
    initChart() {
      if (this.chart) {
        this.chart.destroy(); // Ensure old chart is removed
      }

      const ctx = this.$refs.graphChart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "line",
        data: this.processedData || { labels: [], datasets: [] },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: true,
                color: "#f0f0f0",
              },
              ticks: {
                autoSkip: false,
              },
            },
            y: {
              grid: {
                display: true,
                color: "#f0f0f0",
              },
              ticks: {
                stepSize: 1, // Ensure proper spacing on Y-axis
              },
              suggestedMin: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return monthToYearMonth(tooltipItem.raw); // Use your helper function
                },
              },
            },
          },
        },
      });
    },
  },
  computed: {
    processedData() {
      const labels = [];

      for (const data of this.remainingAges) {
        if (!labels.includes(data.year)) labels.push(data.year);
        else labels.push("");
      }

      const datasets = [
        {
          label: "Sisa Umur Manfaat",
          data: this.remainingAges.map((item) =>
            Number(item.useful_life_months)
          ),
          backgroundColor: "#ABD1C6",
          borderColor: "#ABD1C6",
          borderWidth: 1,
        },
      ];

      return { labels, datasets };
    },
  },
};
</script>

<template>
  <div class="py-6 chart-container" style="position: relative; height: 400px">
    <canvas ref="graphChart"></canvas>
  </div>
</template>
