<script>
export default {
  data() {
    const today = new Date();
    const currentDate = today.toISOString().split("T")[0];
    const startDate = new Date(today.getFullYear(), today.getMonth(), 2);
    const startDateString = startDate.toISOString().split("T")[0];

    return {
      startDateMenu: false,
      endDateMenu: false,
      startDate: startDateString,
      endDate: currentDate,
      maxDate: currentDate,
    };
  },
  computed: {
    minStartDate() {
      return "1900-01-01"; // Start date can be any date before 1900
    },
    minEndDate() {
      return this.startDate || this.maxDate;
    },
    maxEndDate() {
      const startDate = new Date(this.startDate);
      const maxEndDate = new Date(
        startDate.getTime() + 30 * 24 * 60 * 60 * 1000
      ); // Max 30 days

      return maxEndDate.toISOString().split("T")[0]
      // const today = new Date();
      // return maxEndDate > today
      //   ? today.toISOString().split("T")[0]
      //   : maxEndDate.toISOString().split("T")[0];
    },
  },
  watch: {
    startDate(newStartDate) {
      // Menghitung tanggal terakhir bulan berdasarkan startDate yang baru
      const startDateObj = new Date(newStartDate);
      const endDateObj = new Date(
        startDateObj.getFullYear(),
        startDateObj.getMonth() + 1,
        0
      ); // Last day of the selected month
      endDateObj.setHours(23, 59, 59, 999); // Pastikan akhir bulan berada di akhir hari

      // Menghitung tanggal 30 hari setelah startDate
      const adjustedEndDate = new Date(
        startDateObj.getTime() + 30 * 24 * 60 * 60 * 1000
      ); // 30 days after start date

      // Memastikan endDate tidak lebih dari tanggal saat ini
      const today = new Date();
      const maxEndDate = adjustedEndDate > today ? today : adjustedEndDate;

      // Memilih tanggal akhir yang lebih sesuai: antara akhir bulan atau 30 hari setelah startDate
      this.endDate =
        maxEndDate > endDateObj
          ? endDateObj.toISOString().split("T")[0]
          : maxEndDate.toISOString().split("T")[0];

      this.emitDateUpdates();
    },
    endDate() {
      this.emitDateUpdates();
    },
  },

  methods: {
    emitDateUpdates() {
      this.$emit("update:startDate", this.startDate);
      this.$emit("update:endDate", this.endDate);
      this.$emit("update:formattedDateRange", this.formattedDateRange);
    },
  },
  mounted() {
    // Emit default values on mount
    this.emitDateUpdates();
  },
};
</script>

<template>
  <v-row no-gutters justify="end">
    <v-col cols="6">
      <v-sheet class="px-2" style="background-color: transparent">
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="auto"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              placeholder="Mulai"
              class="pa-0 ma-0 rounded-xl"
              :outlined="true"
              hide-details
              v-model="startDate"
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="startDateMenu = true" class="cursor-pointer pr-2" style="color: #004643;"
                  >mdi-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            :min="minStartDate"
            :max="maxDate"
            @input="startDateMenu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-sheet>
    </v-col>
    <v-col cols="6">
      <v-sheet class="px-2" style="background-color: transparent">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="auto"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              placeholder="Akhir"
              class="pa-0 ma-0 rounded-xl"
              :outlined="true"
              hide-details
              v-model="endDate"
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="endDateMenu = true" class="cursor-pointer pr-2" style="color: #004643;"
                  >mdi-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            :min="minEndDate"
            :max="maxEndDate"
            @input="endDateMenu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #abd1c6;
}
</style>
