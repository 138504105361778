<template>
  <v-container class="py-5" style="max-width: 90vw">
    <v-dialog v-model="deleteDialog" width="434">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <p>Apakah Anda yakin ingin menghapus aset ini?</p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="
              deleteDialog = false;
              deleteAsset();
            "
          >
            Ya
          </v-btn>
          <v-btn color="red" text @click="deleteDialog = false"> Tidak</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="9">
        <div style="display: flex" class="justify-space-between align-center">
          <div>
            <title-icon-component
                :title="`Detail ${asset.asset_name}`"
                image="gedung_bangunan.svg"
            />
            <breadcrumbs-component :crumbs="crumbs"/>
          </div>
          <div style="display: flex" class="align-center justify-center">
            <v-btn
                v-if="!asset.deleted_at"
                @click="deleteDialog = true"
                class="text-capitalize mr-2"
                outlined
                color="red"
                :disabled="
                !this.account.scopes.includes(
                  this.scopeConstant.manage_asset
                ) &&
                !this.account.scopes.includes(this.scopeConstant.delete_asset)
              "
            >
              Hapus Aset
            </v-btn>
            <v-btn
                @click="editAset()"
                class="text-capitalize"
                :disabled="
                !this.account.scopes.includes(this.scopeConstant.manage_asset)
              "
            >
              Edit Aset
            </v-btn>
            <asset-option-component
                :account="account"
                :asset="asset"
                :scope-constant="scopeConstant"
                redirect="Gedung Bangunan"
            />
          </div>
        </div>
        <div class="mt-10">
          <div>
            <photo-group-component
                :images="asset.image_urls"
                class="mb-5"
                v-if="!isEditable"
            />
            <v-container
                style="border-radius: 20px; border: 1px #abd1c6 solid"
                class="px-7 py-7 mb-5"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <card-detail-component label="System ID" :value="asset.id"/>
                </v-col>
                <template v-for="property in properties">
                  <v-col cols="12" md="6" :key="property.key">
                    <card-detail-component
                        :label="property.label"
                        :value="asset[property.key]"
                        :editable="isEditable"
                        v-model="asset[property.key]"
                        :rules="property.rules"
                        :max-length="property.maxLength"
                    />
                  </v-col>
                </template>
                <!-- Tanggal Perolehan -->
                <v-col cols="12" md="6">
                  <div
                      style="
                      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
                      border-radius: 15px;
                    "
                  >
                    <div
                        style="
                        background: #bee3d9;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                        class="d-flex align-center"
                    >
                      <h3
                          style="
                          font-size: 16px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                          class="px-3 py-2"
                      >
                        Tanggal Perolehan
                      </h3>
                    </div>
                    <div class="px-3" v-if="isEditable">
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="asset.date_of_acquisition"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="asset.date_of_acquisition"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="asset.date_of_acquisition"
                            no-title
                            scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(asset.date_of_acquisition)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <h5
                        v-else
                        class="px-3 py-2"
                        style="
                        font-size: 14px;
                        font-weight: 400;
                        word-wrap: break-word;
                      "
                    >
                      {{ asset.date_of_acquisition }}
                    </h5>
                  </div>
                </v-col>
                <!-- Umur Manfaat -->
                <v-col cols="12" md="6">
                  <div
                      style="
                      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
                      border-radius: 15px;
                    "
                  >
                    <div
                        style="
                        background: #bee3d9;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                        class="d-flex align-center"
                    >
                      <h3
                          style="
                          font-size: 16px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                          class="px-3 py-2"
                      >
                        Umur Manfaat
                      </h3>
                    </div>
                    <h5
                        class="px-3 py-2"
                        style="
                        font-size: 14px;
                        font-weight: 400;
                        word-wrap: break-word;
                      "
                        v-if="!isEditable"
                    >
                      {{ monthToYearMonth(asset.useful_life_months) }}
                    </h5>
                    <v-col cols="12" v-else>
                      <v-row>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <v-select
                                class="px-3 py-2"
                                placeholder="Pilih Tahun"
                                v-model="asset.useful_life_year"
                                :items="Array.from({ length: 100 }, (_, i) => i)"
                                dense
                            ></v-select>
                            <span class="mx-2">Tahun</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <v-select
                                class="px-3 py-2"
                                placeholder="Pilih Bulan"
                                v-model="asset.useful_life_month"
                                :items="Array.from({ length: 12 }, (_, i) => i)"
                                dense
                            ></v-select>
                            <span class="mx-2">Bulan</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
                <!-- Sisa Umur Manfaat -->
                <v-col cols="12" md="6">
                  <div
                      style="
                      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
                      border-radius: 15px;
                    "
                  >
                    <div
                        style="
                        background: #bee3d9;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                        class="d-flex align-center"
                    >
                      <h3
                          style="
                          font-size: 16px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                          class="px-3 py-2"
                      >
                        Sisa Umur Manfaat
                      </h3>
                    </div>
                    <h5
                        class="px-3 py-2"
                        style="
                        font-size: 14px;
                        font-weight: 400;
                        word-wrap: break-word;
                      "
                    >
                      {{ monthToYearMonth(asset.useful_life_months_remaining) }}
                    </h5>
                  </div>
                </v-col>
                <!-- Foto -->
                <v-col cols="12" md="6" v-if="isEditable">
                  <div
                      style="
                      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
                      border-radius: 15px;
                    "
                  >
                    <div
                        style="
                        background: #bee3d9;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                        class="d-flex align-center"
                    >
                      <h3
                          style="
                          font-size: 16px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                          class="px-3 py-2"
                      >
                        Foto Aset
                      </h3>
                    </div>
                    <div>
                      <div class="mx-3 my-2 py-2">
                        <label id="label-image" for="file-upload">+</label>
                        <input
                            type="file"
                            id="file-upload"
                            multiple
                            @change="handleFileUpload"
                            accept="image/*"
                        />
                        <div class="image-container">
                          <div
                              v-for="(image, index) in images"
                              :key="index"
                              class="image-item"
                          >
                            <img
                                :src="image.previewUrl || image"
                                alt="Preview"
                                class="pic"
                            />
                            <button
                                class="remove-button"
                                @click="removeImage(index, image)"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <template v-for="(item, index) in customItems">
                  <v-col cols="12" md="6" :key="index">
                    <card-detail-component
                        :label="item.information_name"
                        :value="item.information_value"
                        :editable="isEditable"
                        v-model="item.information_value"
                        :isCustom="true"
                        @closeCard="removeCustomItem(index, item.id)"
                    />
                  </v-col>
                </template>

                <v-col cols="12" md="6" v-if="isEditable">
                  <v-dialog v-model="dialog" max-width="434px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="mx-2"
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          @click="addCard"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-form ref="form" v-model="valid">
                        <v-card-title class="mb-5">
                          Tambah Keterangan
                          <v-spacer></v-spacer>
                          <v-btn icon @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <h4>Nama Keterangan</h4>
                          <v-text-field
                              class="py-0"
                              required
                              v-model="newCard.label"
                              :rules="[
                              !!newCard.label ||
                                'Nama Keterangan tidak boleh kosong',
                            ]"
                          ></v-text-field>
                          <h4>Isi Keterangan</h4>
                          <v-text-field
                              class="py-0"
                              required
                              v-model="newCard.value"
                              :rules="[
                              !!newCard.value ||
                                'Isi Keterangan tidak boleh kosong',
                            ]"
                          >
                          </v-text-field>
                          <div class="text-center mt-auto">
                            <v-btn
                                class="white--text text-capitalize"
                                color="#004643"
                                width="80%"
                                @click="submitForm"
                            >Simpan
                            </v-btn
                            >
                          </div>
                        </v-card-text>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>

              <v-row justify="center" v-if="isEditable">
                <v-btn
                    @click="
                    isEditable = false;
                    submitAset();
                  "
                    class="white--text text-capitalize ml-2"
                    color="#004643"
                >Simpan
                </v-btn>
              </v-row>
            </v-container>
            <v-container
                style="border-radius: 20px; border: 1px #abd1c6 solid"
                class="px-7 py-7"
                id="graph-aset"
            >
              <h4>Grafik Sisa Umur Manfaat</h4>
              <remaining-age-chart-component
                  v-if="asset && asset.id"
                  :assetId="asset.id"
              />
            </v-container>
          </div>
          <div></div>
        </div>
      </v-col>
      <v-col cols="3" class="px-5 py-5">
        <title-component title="Riwayat Aset Ini" class="mb-12"/>
        <div v-for="history in computedHistories" :key="history.id">
          <riwayat-aset-mini-card
              :penyebab="history.status"
              :waktu-kejadian="
              history.start_date?.slice(0, 10) ||
              history.created_at?.slice(0, 10)
            "
              :waktu-selesai="history.end_date?.slice(0, 10)"
              :source="history.source"
              :id="
              history.accident_id ||
              history.asset_disruption_id ||
              history.initial_report_id
            "
              :history-id="history.id"
              :history-editor="history.editor"
              :open-popup="openDetailLog"
              :budget-source="history.budget_source"
              :additional-value="history.additional_value"
              :useful-life-months-reduction="history.useful_life_months_reduction"
              :useful-life-months-addition="history.useful_life_months_addition"
          />
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card>
        <v-card-text>
          Mohon tunggu
          <v-progress-linear
              indeterminate
              color="green"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHistory" width="500">
      <v-card class="px-10 py-10">
        <h1>Diubah</h1>

        <div style="font-size: 18px; font-weight: bold">Diubah Oleh</div>
        <div style="font-size: 14px; font-weight: normal">{{ editorName }}</div>
        <hr/>

        <div v-for="log in logs" :key="log.id">
          <div class="mt-8">
            <div style="font-size: 18px; font-weight: bold">Keterangan</div>
            <div style="font-size: 14px; font-weight: normal">
              {{ log.field }}
            </div>
            <hr/>
          </div>

          <div class="mt-3">
            <div style="font-size: 18px; font-weight: bold">Isi</div>
            <div style="font-size: 14px; font-weight: normal">
              <b>{{ log.before || "-" }}</b> to <b>{{ log.after }}</b>
            </div>
            <hr/>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TitleIconComponent from "@/components/TitleIconComponent.vue";
import TitleComponent from "@/components/TitleComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import CardDetailComponent from "@/components/CardDetailComponent";
import PhotoGroupComponent from "@/components/PhotoGroupComponent";
import RiwayatAsetMiniCard from "@/components/RiwayatAsetMiniCard";
import AssetOptionComponent from "@/components/AssetOptionComponent.vue";
import Cookies from "js-cookie";
import * as constant from "@/constants/constant";
import {monthToYearMonth} from "@/plugins/helpers";
import RemainingAgeChartComponent from "@/components/RemainingAgeChartComponent.vue";

export default {
  components: {
    TitleComponent,
    TitleIconComponent,
    CardDetailComponent,
    BreadcrumbsComponent,
    PhotoGroupComponent,
    RiwayatAsetMiniCard,
    AssetOptionComponent,
    RemainingAgeChartComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    monthToYearMonth,
    removeImage(index, imageUrl) {
      this.images.splice(index, 1);
      if (imageUrl.previewUrl) return;
      const params = {
        image_url: imageUrl,
      };
      this.$axios
          .delete(`/asset-images/${this.asset.id}`, {params})
          .catch((err) => {
            console.log(err);
          });
    },
    deleteAsset() {
      const payload = {
        assetId: this.asset.id,
        assetStatus: "Dihapus",
        executionDate: new Date(),
      };
      this.$axios.post("/asset-history/" + this.asset.id, payload);
      this.$axios
          .delete(`/assets/${this.asset.id}`)
          .then(() => {
            this.$router.push({name: "Gedung Bangunan"});
          })
          .catch((err) => {
            console.log(err);
          });
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images.push({
            file: files[i],
            previewUrl: e.target.result,
          });
        };
        reader.readAsDataURL(files[i]);
      }
    },
    editAset() {
      this.isEditable = true;
    },
    addCard() {
      if (this.newCard.label && this.newCard.value) {
        this.customItems.push({
          information_name: this.newCard.label,
          information_value: this.newCard.value,
          asset_id: this.$route.params.id,
        });

        this.newCard.label = "";
        this.newCard.value = "";
      }
      console.log(this.customItems);
    },
    closeDialog() {
      this.dialog = false;
    },
    submitForm() {
      this.$refs.form.validate();
      if (this.valid) {
        this.addCard();
        this.closeDialog();
      }
    },
    removeCustomItem(index, id) {
      this.customItems.splice(index, 1);
      if (id) {
        this.$axios
            .delete(`/additional-information/${id}`)
            .then(() => {
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    async init() {
      try {
        if (Cookies.get("loginResponse") == undefined)
          window.location.replace("/login");
        this.account = JSON.parse(Cookies.get("loginResponse"));
        const assetResponse = await this.$axios.get(
            `/asset/${this.$route.params.id}`
        );
        this.asset = assetResponse.data.data;
        this.images = this.asset.image_urls;
        this.asset.useful_life_year = Math.floor(
            this.asset.useful_life_months / 12
        );
        this.asset.useful_life_month = this.asset.useful_life_months % 12;

        const additionalInfoResponse = await this.$axios.get(
            `/additional-information?asset_id=${this.$route.params.id}`
        );
        this.customItems =
            additionalInfoResponse.data.data.additional_informations;

        const assetHistories = await this.$axios.get(
            `/history?asset_id=${this.$route.params.id}`
        );
        this.assetHistories = assetHistories.data.data.histories;
      } catch (err) {
        console.error(err);
      }
    },
    async submitAset() {
      try {
        this.dialogLoading = true;
        const imageUrls = [];
        for (let i = 0; i < this.images.length; i++) {
          if (!this.images[i].previewUrl) continue;
          const formData = new FormData();
          formData.append("image", this.images[i].file);
          const response = await this.$axios.post("/upload-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          imageUrls.push(response.data.url);
        }
        await this.$axios.put(`/asset/${this.$route.params.id}`, {
          ...this.asset,
          imageUrls,
          image_urls: [],
          additional: this.customItems,
          useful_life_months:
              this.asset.useful_life_year * 12 + this.asset.useful_life_month,
        });
        // await this.$axios.put(`/additional-information`, {
        //   data: this.customItems,
        // });
        this.message = "Aset berhasil diubah";
        this.snackbar = true;
        this.dialogLoading = false;
        new Promise((resolve) => setTimeout(resolve, 1000)).then(() =>
            this.$router.go()
        );
      } catch (err) {
        this.message = err.response.data.data.error;
        this.snackbar = true;
        this.dialogLoading = false;
        console.error(err);
      }
    },
    async openDetailLog(historyId, editor) {
      try {
        this.dialogLoading = true;
        const resp = await this.$axios.get(
            `/asset-log?history_id=${historyId}`
        );
        this.logs = resp.data.data.logs;
        this.editorName = editor;
        this.dialogLoading = false;
        this.dialogHistory = true;
      } catch (e) {
        console.error(e);
      }
    },
  },
  data: () => ({
    account: null,
    scopeConstant: constant.SCOPES,
    timeout: 2000,
    message: "",
    dialogLoading: false,
    snackbar: false,
    assetHistories: [],
    deleteDialog: false,
    properties: [
      {
        label: "Kode Barang", key: "asset_code", rules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 16) || "Kode barang harus terdiri dari 16 karakter.",
          v => (v && v.length <= 16) || "Kode barang harus terdiri dari 16 karakter.",
        ],
        maxLength: 16,
      },
      {
        label: "Kode Lokasi", key: "asset_location", rules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 22) || "Kode Lokasi harus terdiri dari 22 karakter.",
          v => (v && v.length <= 22) || "Kode Lokasi harus terdiri dari 22 karakter.",
        ],
        maxLength: 22,
      },
      {label: "Seksi", key: "section"},
      {label: "Koordinat", key: "coordinate"},
      {label: "Pemilik", key: "asset_owner"},
      {label: "Pengembang", key: "asset_developer"},
      {label: "Operator", key: "asset_operator"},
      {label: "Keterangan Tambahan", key: "information"},
      {label: "Harga Perolehan", key: "price_of_acquisition"},
    ],
    valid: true,
    isEditable: false,
    newCard: {
      label: "",
      value: "",
    },
    customItems: [],
    dialog: false,
    asset: {},
    images: [],
    menu: false,
    dialogHistory: false,
    logs: [],
    editorName: "",
  }),
  computed: {
    computedHistories() {
      return this.assetHistories.map((data) => ({
        ...data,
        status:
            data.asset_status == "Diupload"
                ? `Diupload oleh ${data.editor}`
                : data.asset_status,
      }));
    },
    crumbs() {
      return [
        {
          text: "Layanan Aset",
          disabled: false,
          href: "/aset",
        },
        {
          text: "Gedung dan Bangunan",
          disabled: false,
          href: "/aset/gedung-bangunan",
        },
        {
          text: `Detail ${this?.asset?.asset_name}`,
          disabled: true,
          href: "/aset/gedung-bangunan/" + this?.asset?.id,
        },
      ];
    },
  },
};
</script>

<style></style>
