<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component title="Halaman Admin" />
<!--    <h3>List Akun</h3>-->
    <v-btn color="blue" @click="openCreateForm">Buat Akun</v-btn>
    <table-component
        class="mt-5"
        :headers="tableHeaders"
        :items="computedUserItems"
    />

    <v-dialog v-model="dialogCreate" width="50vw">
      <v-card class="px-10 py-10">
        <h4>Create User</h4>
        <v-text-field
            required
            v-model="userData.nik"
            label="Username"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.fullname"
            label="Nama Lengkap"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.birthplace"
            label="Tempat Lahir"
            class="mb-2"
        ></v-text-field>

        <label>Tanggal Lahir</label>
        <v-menu
            ref="menu"
            v-model="showDatePicker"
            :close-on-content-click="false"
            :return-value.sync="userData.birthdate"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="userData.birthdate"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="userData.birthdate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showDatePicker = false">
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(userData.birthdate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
            required
            v-model="userData.password"
            label="Password"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.email"
            label="Email"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.address"
            label="Alamat"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.phone"
            label="No. HP"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.role"
            label="Pekerjaan"
            class="mb-2"
        ></v-text-field>

        <v-select
            v-model="userData.scopes"
            clearable
            chips
            label="Otoritas"
            :items="scopeOpts"
            multiple
        ></v-select>

        <v-row class="mt-5">
          <v-btn color="blue" @click="submitCreateForm">Buat Akun</v-btn>
          <v-btn class="ml-4" color="red" @click="dialogCreate = false">Kembali</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditData" width="50vw">
      <v-card class="px-10 py-10">
        <h4>Edit User</h4>
        <v-text-field
            required
            v-model="userData.fullname"
            label="Nama Lengkap"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.birthplace"
            label="Tempat Lahir"
            class="mb-2"
        ></v-text-field>

        <label>Tanggal Lahir</label>
        <v-menu
            ref="menu"
            v-model="showDatePicker"
            :close-on-content-click="false"
            :return-value.sync="userData.birthdate"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="userData.birthdate"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="userData.birthdate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showDatePicker = false">
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(userData.birthdate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
            required
            v-model="userData.email"
            label="Email"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.address"
            label="Alamat"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.phone"
            label="No. HP"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="userData.role"
            label="Pekerjaan"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            v-model="userData.password"
            label="Password"
            class="mb-2"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.min]"
            @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-row class="mt-5">
          <v-btn color="blue" @click="submitEditDataForm">Edit Akun</v-btn>
          <v-btn class="ml-4" color="red" @click="dialogEditData = false">Kembali</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUpdateScope" width="50vw">
      <v-card class="px-10 py-10">
        <h4>Edit Scopes</h4>
        <v-select
            v-model="userData.scopes"
            clearable
            chips
            label="Otoritas"
            :items="scopeOpts"
            multiple
        ></v-select>

        <v-row class="mt-5">
          <v-btn color="blue" @click="submitUpdateScopeForm">Edit Otoritas</v-btn>
          <v-btn class="ml-4" color="red" @click="dialogUpdateScope = false">Kembali</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import TableComponent from "@/components/TableComponent.vue";

export default {
  components: { TitleComponent, TableComponent  },
  name: "AdminDashboardPage",
  beforeMount() {
    this.init();
  },
  computed: {
    computedUserItems() {
      console.log("check user:", this.users);
      return this.users.map((data) => ({
        ...data,
        editData: () => this.openEditDataForm(data),
        updateScopes: () => this.openUpdateScopesForm(data),
        deleteUser: () => this.deleteUser(data.id)
      }))
    }
  },
  data: () => ({
    snackbar: false,
    timeout: 3000,
    message: "",

    rules : {
      min: v => v.length >= 8 || 'Min 8 characters',
    },

    //data table
    tableHeaders: [
      { text: "Username", value: "nik" },
      { text: "Nama Lengkap", value: "fullname" },
      { text: "Tempat Lahir", value: "birth_place" },
      { text: "Tanggal Lahir", value: "birth_date" },
      { text: "Email", value: "email" },
      { text: "Alamat", value: "employee_address" },
      { text: "No. HP", value: "phone" },
      { text: "Pekerjaan", value: "role" },
      { text: "Otoritas", value: "scopes" },
      {
        text: "",
        value: "action",
        sortable: false,
        filterable: false,
        align: "center",
      },
    ],
    users: [],

    //create or edit user
    showPassword: false,
    dialogCreate: false,
    dialogEditData: false,
    dialogUpdateScope: false,
    selectedUserId: null,
    userData: {},
    showDatePicker: false,
    scopeOpts: []
  }),
  methods: {
    async init() {
      this.$axios
          .get("/users")
          .then((response) =>
              console.log((this.users = response.data.data))
          );

      this.$axios.get("/scopes")
          .then((resp) => console.log((this.scopeOpts = resp.data.data)));
    },

    openCreateForm() {
      this.userData = {
        nik: "",
        fullname: "",
        birthplace: "",
        birthdate: "",
        password: "",
        email: "",
        address: "",
        phone: "",
        role: "",
        scopes: []
      }

      this.dialogCreate = true
    },

    openEditDataForm(data) {
      this.selectedUserId = data.id;
      this.userData = {
        fullname: data.fullname,
        birthplace: data.birth_place,
        birthdate: data.birth_date,
        email: data.email,
        address: data.employee_address,
        phone: data.phone,
        image_url: data.image_url,
        role: data.role,
      };

      this.dialogEditData = true;
    },

    openUpdateScopesForm(data) {
      this.selectedUserId = data.id;
      this.userData = {
        scopes: data.scopes
      }

      this.dialogUpdateScope = true;
    },

    async submitCreateForm() {
      await this.$axios.post("/users", this.userData).then((resp) => {
        if (resp.data.status == 'Ok') {
          this.message = "Akun berhasil dibuat"
        } else {
          this.message = resp.data.status;
        }
        this.snackbar = true;
        this.dialogCreate = false;
        this.init()
      })
    },

    async submitEditDataForm() {
      await this.$axios.put("/users/" + this.selectedUserId, this.userData).then((resp) => {
        if (resp.data.status == 'Ok') {
          this.message = "Data akun berhasil diubah"
        } else {
          this.message = resp.data.status;
        }
        this.snackbar = true;
        this.dialogEditData = false;
        this.init()
      })
    },

    async submitUpdateScopeForm() {
      await this.$axios.patch("/user-scopes/" + this.selectedUserId, this.userData).then((resp) => {
        if (resp.data.status == 'Ok') {
          this.message = "Otoritas berhasil diubah"
        } else {
          this.message = resp.data.status;
        }
        this.snackbar = true;
        this.dialogUpdateScope = false;
        this.init()
      })
    },

    async deleteUser(id) {
      await this.$axios.delete("/users/" + id).then((resp) => {
        if (resp.data.status == 'Ok') {
          this.message = "Akun berhasil dihapus"
        } else {
          this.message = resp.data.status;
        }
        this.snackbar = true;
        this.init()
      })
    }
  }
}
</script>

<style>

</style>