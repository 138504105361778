var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.items,"items-per-page":20,"footer-props":{
    'items-per-page-options': [5, 10, 15, 20],
  },"hide-default-footer":_vm.hideFooter},scopedSlots:_vm._u([{key:`item.pilih`,fn:function({ item }){return [_c('popup-component',{attrs:{"text":"Pilih"},on:{"dialog-closed":function($event){_vm.step = 1}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h6"}),_c('v-card-text',[_c('v-container',{staticClass:"px-4"},[(_vm.step === 1)?[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Re-kategorisasi")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('h4',{staticClass:"subtitle-1 mb-2"},[_vm._v("Pengurangan Umur Manfaat")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"placeholder":"Pilih Tahun","items":Array.from({ length: 100 }, (_, i) => i),"dense":"","required":"","type":"number","rules":[
                              _vm.reductionYear ?? 'Isi tidak boleh kosong',
                            ]},model:{value:(_vm.reductionYear),callback:function ($$v) {_vm.reductionYear=_vm._n($$v)},expression:"reductionYear"}}),_c('span',{staticClass:"mx-2"},[_vm._v("Tahun")])],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"placeholder":"Pilih Bulan","items":Array.from({ length: 12 }, (_, i) => i),"dense":"","required":"","type":"number","rules":[
                              _vm.reductionMonth ?? 'Isi tidak boleh kosong',
                            ]},model:{value:(_vm.reductionMonth),callback:function ($$v) {_vm.reductionMonth=_vm._n($$v)},expression:"reductionMonth"}}),_c('span',{staticClass:"mx-2"},[_vm._v("Bulan")])],1)])],1)],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('h4',{staticClass:"subtitle-1 mb-2"},[_vm._v("Penambahan Umur Manfaat")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"placeholder":"Pilih Tahun","items":Array.from({ length: 100 }, (_, i) => i),"dense":"","required":"","type":"number","rules":[
                              _vm.additionalYear ?? 'Isi tidak boleh kosong',
                            ]},model:{value:(_vm.additionalYear),callback:function ($$v) {_vm.additionalYear=_vm._n($$v)},expression:"additionalYear"}}),_c('span',{staticClass:"mx-2"},[_vm._v("Tahun")])],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"placeholder":"Pilih Bulan","items":Array.from({ length: 12 }, (_, i) => i),"dense":"","required":"","type":"number","rules":[
                              _vm.additionalMonth ?? 'Isi tidak boleh kosong',
                            ]},model:{value:(_vm.additionalMonth),callback:function ($$v) {_vm.additionalMonth=_vm._n($$v)},expression:"additionalMonth"}}),_c('span',{staticClass:"mx-2"},[_vm._v("Bulan")])],1)])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"#004643"},on:{"click":_vm.nextStep}},[_vm._v(" Simpan ")])],1)],1)]:(_vm.step === 2)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Apakah anda yakin memilih aset ini?")])])],1),_c('v-row',{staticClass:"my-0 mt-2 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-subtitle-1"},[_vm._v("Aset: "+_vm._s(item.asset_code))])])],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" Pengurangan Umur Manfaat : "+_vm._s(_vm.reductionYear)+" Tahun "+_vm._s(_vm.reductionMonth)+" Bulan ")])])],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" Penambahan Umur Manfaat : "+_vm._s(_vm.additionalYear)+" Tahun "+_vm._s(_vm.additionalMonth)+" Bulan ")])])],1)]:_vm._e()],2)],1),(_vm.step === 2)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.step = 1;
              slotProps.closeDialog();
              _vm.submit(item);}}},[_vm._v(" Ya ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.step = 1;
              slotProps.closeDialog();}}},[_vm._v(" Tidak ")])],1):_vm._e()],1)]}}],null,true)})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }