export function formatRupiah(angka) {
    if (angka == null) {
        angka = 0
    }

    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    }).format(angka)
}

export function formatKilometer(km) {
    if (km) {
        let kmStr = km.toString().trim();
        let isNumeric = !isNaN(kmStr) && !isNaN(parseInt(kmStr));

        if (isNumeric) {
            if (kmStr.length === 1) {
                kmStr = kmStr.padStart(2, '0');
            }
            
            if (kmStr.length < 6) {
                kmStr = kmStr.padEnd(5, '0');
                return `${kmStr.slice(0, 2)}+${kmStr.slice(2, 5)}`
            } else {
                let suffix = kmStr.slice(-3)
                let prefix = kmStr.slice(0, -3)
                return `${prefix}+${suffix}`
            }
        }

        const hasKmPrefix = kmStr.startsWith("KM");
        if (!hasKmPrefix) return km
        
        const parts = kmStr.split('-').map(part => part.trim())
        let kmPart = parts[0]

        const numericMatch = kmPart.match(/KM\s*(\d+)/);
        if (numericMatch) {
            let kmValue = numericMatch[1];

            // Format the numeric value
            if (kmValue.length === 1) {
                kmValue = kmValue.padStart(2, '0');
            } 

            if (kmValue.length < 6) {
                kmValue = kmValue.padEnd(5, '0');
                kmPart = `KM ${kmValue.slice(0, 2)}+${kmValue.slice(2, 5)}`
            } else {
                let suffix = kmValue.slice(-3)
                let prefix = kmValue.slice(0, -3)
                kmPart = `KM ${prefix}+${suffix}`
            }

            parts[0] = kmPart;
        }

        return parts.join(' - ');
        
    }
    return '';
}

export function monthToYearMonth(month) {
    const isNegative = month < 0;
    const absMonth = Math.abs(month);
    const year = Math.floor(absMonth / 12);
    const monthRemainder = absMonth % 12;
    
    const result = [];
    if (year > 0) result.push(`${year} Tahun`);
    if (monthRemainder > 0 || year === 0) result.push(`${monthRemainder} Bulan`);
    
    return isNegative ? `-${result.join(' ')}` : result.join(' ');
}

