<template>
  <div>
    <v-col cols="12" style="
    box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
    border-radius: 5px;
    ">
      <v-row
          class="header-table-detail px-3 py-2 justify-space-between align-center"
          style="display: flex"
      >
        <v-col cols="6">
          <h4>
            {{ title1 }}
          </h4>
        </v-col>
        <v-col cols="6">
          <h4>
            {{ title2 }}
          </h4>
        </v-col>
      </v-row>
      <v-row
          class="px-3 py-2"
      >
        <v-col cols="6">
          <div
              class="mb-2 data-group-card"
              v-for="detailOne in dataDetailOne"
              :key="detailOne"
          >
            <h5 class="detail-label">{{ detailOne.labelName }}</h5>
            <p class="detail-val body-2 mt-1">{{ detailOne.value }}</p>
          </div>
        </v-col>
        <v-col cols="6">
          <div
              class="mb-2 data-group-card"
              v-for="detailTwo in dataDetailTwo"
              :key="detailTwo"
          >
            <h5 class="detail-label">{{ detailTwo.labelName }}</h5>
            <p class="detail-val body-2 mt-1">{{ detailTwo.value }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-block" v-if="images !== undefined && images.length > 0">
            <h6 class="my-2 text-center">Foto Identitas Pengemudi</h6>
            <v-row>
              <div class="mt-2 mx-2 p-2" style="width: 100px; height: 100px" v-for="(image, index) in images"
                   v-bind:key="index">
                <v-img class="mx-auto" style="border-radius: 20px" width="75%" height="75%" :src="image"
                       @click="openDialog(image)"/>
              </div>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="dialog" max-width="80vw">
      <v-card>
        <v-img :src="imageUrl" max-height="80vh" contain/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["title1", "title2", "dataDetailOne", "dataDetailTwo", "images"],
  methods: {
    openDialog(image) {
      this.imageUrl = image;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style>
.header-table-detail {
  color: #004643;
  background: #abd1c6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.detail-label {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 0 !important;
}

.detail-val {
  word-wrap: break-word;
  margin-bottom: 0 !important;
}

.data-group-card {
  display: flex;
  flex-direction: column;
}
</style>
