<template>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="items"
    class="elevation-1"
    :items-per-page="20"
    :footer-props="{
      'items-per-page-options': [5, 10, 15, 20],
    }"
    :hide-default-footer="hideFooter"
  >
    <template v-slot:[`item.pilih`]="{ item }">
      <popup-component
        text="Pilih"
        v-slot="slotProps"
        @dialog-closed="step = 1"
      >
        <v-card>
          <v-card-title class="text-h6"></v-card-title>
          <v-card-text>
            <v-container class="px-4">
              <template v-if="step === 1">
                <v-form ref="form" v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <p>Re-kategorisasi</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-2">
                      <h4 class="subtitle-1 mb-2">Pengurangan Umur Manfaat</h4>
                      <v-row>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <v-select
                              placeholder="Pilih Tahun"
                              v-model.number="reductionYear"
                              :items="Array.from({ length: 100 }, (_, i) => i)"
                              dense
                              required
                              type="number"
                              :rules="[
                                reductionYear ?? 'Isi tidak boleh kosong',
                              ]"
                            ></v-select>
                            <span class="mx-2">Tahun</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <v-select
                              placeholder="Pilih Bulan"
                              v-model.number="reductionMonth"
                              :items="Array.from({ length: 12 }, (_, i) => i)"
                              dense
                              required
                              type="number"
                              :rules="[
                                reductionMonth ?? 'Isi tidak boleh kosong',
                              ]"
                            ></v-select>
                            <span class="mx-2">Bulan</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <h4 class="subtitle-1 mb-2">Penambahan Umur Manfaat</h4>
                      <v-row>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <v-select
                              placeholder="Pilih Tahun"
                              v-model.number="additionalYear"
                              :items="Array.from({ length: 100 }, (_, i) => i)"
                              dense
                              required
                              type="number"
                              :rules="[
                                additionalYear ?? 'Isi tidak boleh kosong',
                              ]"
                            ></v-select>
                            <span class="mx-2">Tahun</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <v-select
                              placeholder="Pilih Bulan"
                              v-model.number="additionalMonth"
                              :items="Array.from({ length: 12 }, (_, i) => i)"
                              dense
                              required
                              type="number"
                              :rules="[
                                additionalMonth ?? 'Isi tidak boleh kosong',
                              ]"
                            ></v-select>
                            <span class="mx-2">Bulan</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-btn
                      color="#004643"
                      class="white--text"
                      @click="nextStep"
                    >
                      Simpan
                    </v-btn>
                  </v-row>
                </v-form>
              </template>
              <template v-else-if="step === 2">
                <v-row>
                  <v-col cols="12">
                    <p>Apakah anda yakin memilih aset ini?</p>
                  </v-col>
                </v-row>
                <v-row class="my-0 mt-2 py-0">
                  <v-col cols="12" class="my-0 py-0">
                    <p class="text-subtitle-1">Aset: {{ item.asset_code }}</p>
                  </v-col>
                </v-row>
                <v-row class="my-0 py-0">
                  <v-col cols="12" class="my-0 py-0">
                    <p class="text-subtitle-1">
                      Pengurangan Umur Manfaat : {{ reductionYear }} Tahun
                      {{ reductionMonth }} Bulan
                    </p>
                  </v-col>
                </v-row>
                <v-row class="my-0 py-0">
                  <v-col cols="12" class="my-0 py-0">
                    <p class="text-subtitle-1">
                      Penambahan Umur Manfaat : {{ additionalYear }} Tahun
                      {{ additionalMonth }} Bulan
                    </p>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
          <v-card-actions v-if="step === 2">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                step = 1;
                slotProps.closeDialog();
                submit(item);
              "
            >
              Ya
            </v-btn>
            <v-btn
              color="red"
              text
              @click="
                step = 1;
                slotProps.closeDialog();
              "
            >
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </popup-component>
    </template>
  </v-data-table>
</template>

<script>
import PopupComponent from "@/components/PopupComponent.vue";

export default {
  components: {
    PopupComponent,
  },
  data: () => ({
    additionalMonth: null,
    additionalYear: null,
    reductionMonth: null,
    reductionYear: null,
    step: 1,
    valid: false,
  }),
  methods: {
    async nextStep() {
      if (this.$refs.form.validate()) {
        this.step = 2;
      }
    },
    submit(item) {
      const payload = {
        assetId: item.id,
        assetStatus: this.source || "Inspeksi",
        initialReportId: this.initialReportId,
        usefulLifeMonthsAddition:
          this.additionalYear * 12 + this.additionalMonth,
        usefulLifeMonthsReduction:
          this.reductionYear * 12 + this.reductionMonth,
      };
      this.$axios.post("/asset-history", payload).then(() => {
        this.$router.push({ name: this.redirect });
      });
    },
    closeDialog(value) {
      this.step = 1;
      this.$emit("close-dialog", value);
    },
  },
  props: {
    slotName: String,
    tableTitle: String,
    headers: Array,
    items: Array,
    search: String,
    hideFooter: {
      type: Boolean,
      default: false,
    },
    initialReportId: [String, Number],
    source: String,
    redirect: String,
  },
};
</script>

<style></style>
